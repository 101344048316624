<template>
  <v-container fluid style="height: 100%; overflow: hidden">
    <div>
      <v-row
        v-if="showSettingMaintenanceAlert && customerWebFlag"
        style="display: flex; justify-content: center"
      >
        <div>
          <div class="seedlogo">
            <img
              src="@/assets/LOGO - Seed@2x.png"
              alt="Vue Material Admin"
              width="150"
              height="150"
            />
          </div>
          <div
            class="mt-15 ml-2 class-content"
            style="display: flex; justify-content: center"
          >
            <v-card
              flat
              id="my-card"
              class="ml-15 mt-15 bgcenter"
              style="
                background-color: transparent;
                box-shadow: unset;
                bottom: 170px;
              "
            >
              <div class="content-container">
                <v-row>
                  <v-col cols="12" class="pb-0 row_flex" style="margin-top: 8%">
                    <v-img
                      src="@/assets/yellow_alert.svg"
                      alt="Image Alt Text"
                      max-width="23"
                      max-height="23"
                      class="mr-1 icon-size"
                      style="margin-top: 2%"
                    ></v-img>
                    <label
                      for="preview_title"
                      class="pt-1 preview-title"
                      style="font-weight: bold; font-size: 36px"
                      >{{ preview_title }}</label
                    >
                    <v-img
                      src="@/assets/yellow_alert.svg"
                      alt="Image Alt Text"
                      max-width="23"
                      max-height="23"
                      class="ml-1 icon-size"
                      style="margin-top: 2%"
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="text-center pt-2 pb-0"
                    style="display: flex; justify-content: center"
                  >
                    <label
                      for="preview_row1"
                      class="mb-0 ml-3 mr-3 text_style"
                      style="font-size: 24px"
                      >{{ preview_row1 }}</label
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="text-center pt-2 pb-0"
                    style="display: flex; justify-content: center"
                  >
                    <label
                      for="preview_row2"
                      class="mb-0 ml-3 mr-3 text_style"
                      style="font-size: 20px"
                      >{{ preview_row2 }}</label
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="text-center pt-3 pb-0"
                    style="display: flex; justify-content: center"
                  >
                    <label
                      for="preview_row3"
                      class="mb-0 ml-3 mr-3 text_style"
                      style="font-size: 20px"
                      >{{ preview_row3 }}</label
                    >
                  </v-col>
                </v-row>
                <v-row sett class="ml-2 mr-1 mt-5" style="padding-top: 10px">
                  <v-col class="row_flex">
                    <v-img
                      src="@/assets/images/lineimage.png"
                      alt="Image Alt Text"
                      class="icon-size"
                    ></v-img>
                    <label
                      for="preview_lineId"
                      class="pt-1 pl-3"
                      style="font-size: 20px; margin-top: -6px"
                      >{{ preview_lineId }}</label
                    >
                  </v-col>
                  <v-col class="row_flex">
                    <v-img
                      src="@/assets/images/fb.png"
                      alt="Image Alt Text"
                      class="icon-size"
                    ></v-img>
                    <label
                      for="preview_fb"
                      class="pt-1 pl-3"
                      style="font-size: 20px; margin-top: -6px"
                      >{{ preview_fb }}</label
                    >
                  </v-col>

                  <v-col class="row_flex">
                    <v-img
                      src="@/assets/images/mail.png"
                      alt="Image Alt Text"
                      class="icon-size"
                    ></v-img>
                    <label
                      for="preview_email"
                      class="pt-1 pl-3"
                      style="font-size: 20px; margin-top: -6px"
                      >{{ preview_email }}</label
                    >
                  </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-row class="mt-15" style="justify-content: flex-end">
                  <img
                    src="@/assets/images/QR.png"
                    alt="QR Code"
                    style="width: 90px; height: 90px"
                  />
                </v-row>
              </div>
            </v-card>
          </div>
        </div>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="12" md="6" lg="7" class="hidden-sm-and-down">
          <v-img class="img-intersection" :src="loginImg" />
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="5">
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-menu
              offset-y
              origin="center center"
              :nudge-bottom="10"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-btn text dark v-on="on">
                  <country-flag
                    size="normal"
                    :country="currentflag"
                    class="flagpos"
                  />
                  <span class="text-capitalize lang-title"
                    >&nbsp;&nbsp;{{ currentLangTitle }}</span
                  >
                </v-btn>
              </template>
              <v-list style="width: 155px; z-index: 1" rounded-lg>
                <v-list-item-group v-model="lang">
                  <v-list-item
                    class="d-flex"
                    style="cursor: pointer"
                    v-for="entry in languages"
                    :key="entry.title"
                    @click="
                      changeLocale(
                        entry.language,
                        entry.flag,
                        entry.title,
                        entry.currentname
                      )
                    "
                  >
                    <v-list-item-action>
                      <country-flag :country="entry.flag" size="normal" />
                    </v-list-item-action>
                    <v-list-item-title style="font-size: 16px">
                      {{ entry.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-row>
          <div class="Loginfield align-left">
            <div class="layout column img-res">
              <img
                src="@/assets/LOGO - Seed@2x.png"
                alt="Vue Material Admin"
                width="208"
                height="208"
              />
            </div>
            <div v-if="isLogin">
              <v-layout class="textlayout" column warp style="margin-top: 40px">
                <v-flex>
                  <h2 style="text-align: left" class="LoginText my-4">
                    {{ $t("login") }}
                  </h2>
                </v-flex>
                <v-flex>
                  <p>{{ $t("companycode") }}</p>
                  <v-text-field
                    solo
                    flat
                    dense
                    outlined
                    style="font-size: 16px; border-color: #424242; width: 80%"
                    class="rounded-lg Logintextfield"
                    v-model="model.companycode"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <p>{{ $t("usernamel") }}</p>
                  <v-text-field
                    solo
                    flat
                    dense
                    outlined
                    style="font-size: 16px; border-color: #424242; width: 80%"
                    class="rounded-lg Logintextfield"
                    v-model="model.username"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <p>{{ $t("password") }}</p>
                  <v-text-field
                    solo
                    flat
                    dense
                    outlined
                    :append-icon="isShow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isShow ? 'text' : 'password'"
                    name="password"
                    id="password"
                    style="font-size: 16px; border-color: #424242; width: 80%"
                    class="rounded-lg Logintextfield"
                    v-model="model.password"
                    @click:append="isShow = !isShow"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout
                row
                warp
                style="margin-right: 20%; margin-left: 0%"
                class="rmtext"
              >
                <div class="d-flex">
                  <v-checkbox v-model="rememberMe"></v-checkbox>
                  <p
                    style="
                      color: #424242;
                      font-size: 16px;
                      margin-top: 1.2rem;
                      font-family: 'Poppins', sans-serif;
                    "
                  >
                    {{ $t("remember") }}
                  </p>
                </div>
                <v-flex>
                  <span
                    :ripple="false"
                    class="text-capitalize cursor-class"
                    @click="isLogin = false"
                    text
                    flat
                    style="
                      float: right;
                      color: #a6cc39;
                      opacity: 1;
                      font-size: 16px;
                      font-family: 'Poppins', sans-serif;
                      text-decoration: underline;
                    "
                    >{{ $t("forgetpassword") }}?</span
                  >
                </v-flex>
              </v-layout>
              <v-layout style="margin-top: 25px">
                <v-btn
                  color="#A6CC39"
                  @click="login"
                  :loading="loading"
                  class="login-phone text-capitalize"
                  >{{ $t("login") }}</v-btn
                >
              </v-layout>
            </div>
            <div v-else>
              <v-layout column warp style="margin-top: 40px">
                <v-flex>
                  <h2 style="text-align: left" class="LoginText my-4">
                    {{ $t("resetpassword") }}
                  </h2>
                </v-flex>

                <v-flex>
                  <p>{{ $t("companycode") }}</p>
                  <v-text-field
                    solo
                    flat
                    dense
                    outlined
                    style="font-size: 16px; border-color: #424242; width: 80%"
                    class="rounded-lg Logintextfield"
                    v-model="comCode"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <p>{{ $t("usernamel") }}</p>
                  <v-text-field
                    solo
                    flat
                    dense
                    outlined
                    style="font-size: 16px; border-color: #424242; width: 80%"
                    class="rounded-lg Logintextfield"
                    type="text"
                    v-model="email"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row warp style="margin-right: 20%" class="rmtext">
                <v-flex>
                  <span
                    class="text-capitalize cursor-class"
                    @click="isLogin = true"
                    text
                    flat
                    style="
                      float: right;
                      color: #a6cc39;
                      opacity: 1;
                      font-size: 16px;
                      font-family: 'Poppins', sans-serif;
                      text-decoration: underline;
                    "
                    >{{ $t("backtologin") }}</span
                  >
                </v-flex>
              </v-layout>
              <v-layout style="margin-top: 30px">
                <v-btn
                  color="#A6CC39"
                  style="color: #fff; width: 80%; font-size: 18px"
                  @click="resetPassword()"
                  :loading="loading"
                  class="text-capitalize reset-btn rounded-lg"
                  >{{ $t("resetpassword") }}</v-btn
                >
              </v-layout>
            </div>
          </div>
        </v-col>
        <v-dialog v-model="errordialog" max-width="484">
          <v-card>
            <p style="text-align: left; height: 60px; padding: 20px 24px 0px">
              <span style="font-size: 30px; color: #363636">
                {{ $t("5timeswrong") }}
              </span>
            </p>
            <v-card-text>
              <p style="text-align: left; color: #363636; font-size: 20px">
                {{ $t("sorryfor5timewrong") }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-layout justify-end class="mb-1 mt-1">
                <v-btn
                  style="background-color: #a6cc39; width: 4rem; color: #ffffff"
                  flat="flat"
                  @click.native="errordialog = false"
                  >{{ $t("ok") }}</v-btn
                >
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="484">
          <v-card>
            <p style="text-align: left; height: 60px; padding: 20px 24px 0px">
              <span style="font-size: 30px; color: #363636">
                {{ $t("invalidlogin") }}
              </span>
            </p>
            <v-card-text>
              <p style="text-align: left; color: #363636; font-size: 20px">
                {{ message }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-layout justify-end class="mb-1 mt-1">
                <v-btn
                  style="background-color: #a6cc39; width: 4rem; color: #ffffff"
                  flat="flat"
                  @click.native="dialog = false"
                  >{{ $t("ok") }}</v-btn
                >
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogfgpassword" max-width="484">
          <v-card>
            <p style="text-align: left; height: 60px; padding: 20px 24px 0px">
              <span style="font-size: 30px; color: #363636">
                {{ $t("invalidlogin") }}
              </span>
            </p>
            <v-card-text>
              <p style="text-align: left; color: #363636; font-size: 20px">
                {{ messagefgps }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-layout justify-end class="mb-1 mt-1">
                <v-btn
                  style="background-color: #a6cc39; width: 4rem; color: #ffffff"
                  flat="flat"
                  @click.native="dialogfgpassword = false"
                  >{{ $t("ok") }}</v-btn
                >
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import i18n from "@/translate/i18n";
import CountryFlag from "vue-country-flag";

export default {
  components: {
    CountryFlag,
  },
  data: () => ({
    // url: enurl.apiUrl,
    startdate: null,
    enddate: null,
    currentdate: new Date().toISOString(),
    // currentdate: new Date().toLocaleString("en-US", {
    //   day: "numeric",
    //   month: "numeric",
    //   year: "numeric",
    //   hour: "numeric",
    //   minute: "numeric",
    //   hour12: false, // Use 24-hour format
    // }),
    showSettingMaintenanceAlert: false,
    customerWebFlag: false,
    preview_title: "",
    preview_row1: "",
    preview_row2: "",
    preview_row3: "",
    preview_lineId: "",
    preview_fb: "",
    preview_email: "",
    loading: false,
    dialog: false,
    dialogfgpassword: false,
    errordialog: false,
    checkuserCount: 1,
    erroralert: false,
    checkuser: null,
    is_Locked: false,
    errorMessage:
      "You have failed logging in for 5 times already.Your account is locked!",
    message: "",
    messagefgps: "",
    loginImg: require("@/assets/Mask Group 26.png"),
    imageslogo: require("@/assets/seed_logo1.png"),
    model: {
      username: "",
      password: "",
      companycode: "",
    },
    isShow: false,
    isLogin: true,
    email: "",
    comCode: "",
    rememberMe: false,
    remember: "",
    secretKey: "secretkey123",
    currentflag: "US",
    currentLangTitle: "EN",
    lang: "",
    todaydate: new Date(),
    thaiFlagIcon: true,
    engFlagIcon: false,
    localeFromLogin: null,
    languages: [
      { flag: "US", language: "en", title: "English", currentname: "EN" },
      { flag: "TH", language: "th", title: "Thailand", currentname: "TH" },
      { flag: "VN", language: "vn", title: "Vietnam", currentname: "VM" },
      { flag: "MM", language: "mm", title: "Myanmar", currentname: "MM" },
      { flag: "CN", language: "cn", title: "Chinese", currentname: "CN" },
    ],
  }),
  mounted: function () {
    if (this.$route.params.myparam == "true") {
      this.isLogin = false;
    }

    let login_username = this.readCookie("username");
    let login_password = this.readCookie("password");
    let login_companycode = this.readCookie("companycode");
    this.remember = this.readCookie("rememberme");

    if (this.remember == "true") {
      this.expiryDate = this.readCookie("expiredate");
      if (this.todaydate >= this.expiryDate) {
        document.cookie = "companycode=" + ";expires=" + self.expiryDate;
        document.cookie = "username=" + ";expires=" + self.expiryDate;
        document.cookie = "password=" + ";expires=" + self.expiryDate;
        document.cookie = "rememberme=" + ";expires=" + self.expiryDate;
      } else {
        if (login_username != null && login_password != null) {
          this.model.username = this.cryptoMethods("decrypt", login_username);
          this.model.password = this.cryptoMethods("decrypt", login_password);
          this.model.companycode = this.cryptoMethods(
            "decrypt",
            login_companycode
          );
          this.rememberMe = true;
        }
      }
    } else {
      this.expiryDate = new Date();
      this.expiryDate.setMonth(this.expiryDate.getMonth() + 1);
      document.cookie = "expiredate=" + this.expiryDate;
    }

    switch (this.$route.query.db_locale) {
      case "th":
        this.lang = 1;
        i18n.locale = "th";
        this.currentflag = "TH";
        this.currentLangTitle = "TH";
        break;
      case "vn":
        this.lang = 2;
        i18n.locale = "vn";
        this.currentflag = "VN";
        this.currentLangTitle = "VN";
        break;
      case "cn":
        this.lang = 3;
        i18n.locale = "cn";
        this.currentflag = "CN";
        this.currentLangTitle = "CN";
        break;
      case "mm":
        this.lang = 4;
        i18n.locale = "mm";
        this.currentflag = "MM";
        this.currentLangTitle = "MM";
        break;
      default:
        this.lang = 0;
        i18n.locale = "en";
        this.currentflag = "US";
        this.currentLangTitle = "EN";
        break;
    }
    if(localStorage.getItem("companyID") != null){
      this.getSettingMaintenance(localStorage.getItem("companyID"));
    }
    
  },
  computed: {},
  methods: {
    async getSettingMaintenance(companyid) {
      let that = this;

      await axios
        .get(`${that.web_url}SettingMaintenance/GetSettingMaintenanceMessage?companyID=`+companyid)
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data;
            if (temp != null) {
              that.maintenanceID = temp.id;
              that.preview_title = temp.title;
              that.preview_row1 = temp.messageOne;
              that.preview_row2 = temp.messageTwo;
              that.preview_row3 = temp.messageThree;
              that.preview_lineId = temp.lineID;
              that.preview_fb = temp.facebook;
              that.preview_email = temp.email;
              that.startdate = temp.startDate;
              that.showSettingMaintenanceAlert =
                temp.showSettingMaintenanceAlert;
              that.customerWebFlag = temp.customerWebFlag;

              that.enddate = temp.endDate;
            }
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async resetPassword() {
      let self = this;
      self.loading = true;
      let request = {
        abbreviation: self.comCode,
        username: self.email,
      };
      await axios
        .post(`${self.web_urlV2}Login/Forgetpassword`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            self.messagefgps = response.data.message;
            self.comCode = "";
            self.email = "";
          } else {
            self.messagefgps = response.data.message;
          }
        })
        .catch(function (err) {
          throw err;
        });
      self.dialogfgpassword = true;
      self.loading = false;
    },
    async login() {
      let self = this;
      this.loading = true;
      self.$root.logEvent_tab(self.env + "SeedCustomerWeb_Login");
      const response = await this.getData(
        "Login/LoginCustomerWeb",
        {},
        {
          fullName: this.model.username,
          password: this.model.password,
          abbreviation: this.model.companycode,
        }
      );
      // if invalid user?
      if (response.data.status) {
        // show popup
        this.message = response.data.message;
        this.model.username = "";
        this.model.password = "";
        this.model.companycode = "";
        this.lang = "";
        this.loading = false;
        if (this.checkuserCount == 5) {
          this.LockUser(response.data.data);
          this.dialog = false;
          this.errordialog = true;
        } else {
          this.checkuserCount++;
          this.dialog = true;
        }
      } else {
        if (response.data.data.status) {
          const { userType, token, policyTypeW } = response.data.data;

          localStorage.setItem(
            "vivek_authenticated_user_data",
            JSON.stringify(response.data.data)
          );
          sessionStorage.setItem("token", token);
          localStorage.setItem("UserID", JSON.stringify(response.data.data.id));
          localStorage.setItem("companyID", response.data.data.companyId);
          localStorage.setItem("userName", response.data.data.fullName);
          localStorage.setItem("Abbreviation", response.data.data.abb);
          localStorage.setItem("vivek_token", token);
          localStorage.setItem("policyType", policyTypeW);
          localStorage.setItem("Oldpassword", this.model.password);
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          this.$store.commit("setLoggedIn", true); //check announcements...

          let lang_update =
            this.localeFromLogin != null
              ? this.localeFromLogin
              : response.data.data.language;

          const request = {
            userID: response.data.data.id,
            language: lang_update,
          };
          await this.$axios.post(`${this.web_url}User/UpdateLanguage`, request);
          i18n.locale = lang_update;
          localStorage.setItem("dbLanguage", lang_update);

          if (userType.toLowerCase() == "ext") {
            await this.check_menu_permission();
            let menu = JSON.parse(localStorage.getItem("menu_items") || "[]");
            if (menu[0]) {
              if (menu[0].href) {
                this.$router.push(menu[0].href);
              } else if (menu[0].items[0].href) {
                this.$router.push(menu[0].items[0].href);
              } else {
                this.$router.push("/home");
              }
            }
          } else {
            this.message = "Invalid User";
            this.dialog = true;
            this.loading = false;
            this.$router.push("/login");
            localStorage.setItem("vivek_token", "");
          }
          if (self.rememberMe) {
            document.cookie =
              "companycode=" +
              self.cryptoMethods("encrypt", self.model.companycode) +
              ";expires=" +
              self.expiryDate;
            document.cookie =
              "username=" +
              self.cryptoMethods("encrypt", self.model.username) +
              ";expires=" +
              self.expiryDate;
            document.cookie =
              "password=" +
              this.cryptoMethods("encrypt", this.model.password) +
              ";expires=" +
              this.expiryDate;
            let tempRem =
              this.remember == "true" ? this.remember : this.rememberMe;
            document.cookie =
              "rememberme=" + tempRem + ";expires=" + this.expiryDate;
          } else {
            document.cookie = "expiredate=" + new Date();
          }
           this.getSettingMaintenance(response.data.data.companyId);
        } else {
          this.message = "Company Is Inactive";
          this.model.username = "";
          this.model.password = "";
          this.model.companycode = "";
          this.dialog = true;
          this.loading = false;
        }
        this.loading = false;
       
      }
    },
    readCookie(name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(";");
      for (let c of ca) {
        let dd = c;
        while (dd.charAt(0) === " ") {
          dd = dd.substring(1, dd.length);
        }

        if (dd.indexOf(nameEQ) === 0) {
          return dd.substring(nameEQ.length, dd.length);
        }
      }
      return null;
    },
    cryptoMethods(type, value) {
      let self = this;
      if (value != null || value != "" || value != undefined) {
        if (type == "encrypt") {
          const encryptedText = this.CryptoJS.AES.encrypt(
            value,
            self.secretKey
          ).toString();
          return encryptedText;
        } else {
          const decryptedText = this.CryptoJS.AES.decrypt(
            value,
            self.secretKey
          ).toString(this.CryptoJS.enc.Utf8);
          return decryptedText;
        }
      }
    },
    LockUser(id) {
      let temp = {
        userID: id,
        lock: false,
      };
      axios
        .post(`${this.web_url}User/LockUser`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            this.errordialog = true;
            this.loading = false;
          }
        });
    },
    changeLocale(locale, flag, title, currentname) {
      this.currentflag = flag;
      i18n.locale = locale;
      this.currentLangTitle = currentname;
      this.lang = i18n.locale;

      this.localeFromLogin = locale; //for update db language

      document.cookie = "lang=" + locale;
      document.cookie = "flag=" + flag;
      document.cookie = "title=" + title;
    },
  },
};
</script>

<style scoped>
.row + .row {
  margin-top: 45px;
}
.left-image {
  margin-left: 533px;
}
</style>
<style scoped lang="css">
.seedlogo {
  position: absolute;
  left: 3%;
  justify-content: left;
  align-items: left;
}

.qr_position {
  width: 65px;
  height: 57px;
  position: absolute;
  bottom: 45px;
  right: 83px;
}
.icon-size {
  max-width: 24px;
  max-height: 24px;
}
.row_flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.class-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set height to viewport height for vertical centering */
}
.bgcenter {
  background: url("~@/assets/images/maintenance_bg.svg") center/contain;
  width: 1100px;
  height: 850px;
  top: -100px;
  margin-bottom: 64px;
  padding: 0px;
  display: flex;
  position: relative;
  overflow: hidden !important;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1024px) {
  .bgcenter {
    top: -27px; /* Change top value for laptops */
  }
}
.content-container {
  position: absolute;
  top: 24%;
  left: 0;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
body {
  font-family: "Poppins", sans-serif;
}
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}

.headline {
  font-size: 14px;
  color: red;
}
.img-intersection {
  width: 90%;
  height: 93vh;
  max-width: 100%;
  border-radius: 20px;
  margin-top: 25px;
  margin-left: 30px;
}
.Loginfield {
  background: rgba(255, 255, 255, 0.6);
  padding: 0px 60px 32px;
  margin-right: 60px;
  border-radius: 20px;
}
.LoginText {
  color: #424242;
  font-family: "Poppins", sans-serif;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  font-size: 30px;
}
.Loginfield p {
  font-size: 18px;
  color: #424242;
  margin-bottom: 10px;
}
.login-phone,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.login-phone:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.login-phone:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.login-phone:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.login-phone:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
.flagpos {
  margin-bottom: 0px;
}
@media (max-width: 320px) {
  .reset-btn {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (min-device-width: 1366px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .img-res {
    margin-top: unset !important;
  }
}
@media screen and (min-device-height: 0px) and (max-device-height: 655px) {
  .img-res {
    margin-top: unset !important;
  }
  .img-intersection {
    height: 100% !important;
  }
}
</style>
<style lang="scss">
#no-background-hover::before {
  background-color: transparent !important;
}
.cursor-class {
  cursor: pointer;
  margin-top: 1.2rem;
}
</style>
<style scoped>
::v-deep .v-dialog {
  border-radius: 20px !important;
}
::v-deep .v-icon {
  font-size: 24px !important;
}
::v-deep .v-text-field .v-label {
  font-size: 16px !important;
  color: #47484b;
  opacity: 1;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
.lang-title {
  color: #444444;
  font-size: 16px;
  opacity: 1;
}
::v-deep .v-list-item__action {
  margin-right: 5px !important;
  min-width: unset !important;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .cursor-class {
    margin-left: 1.6rem;
    margin-top: 0.8rem;
  }
  .img-res {
    margin-top: unset !important;
  }
  .login-phone {
    margin-bottom: 3rem !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .cursor-class {
    margin-left: 3.9rem;
    margin-top: 1.3rem;
  }
  .img-res {
    margin-top: 3vh !important;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .cursor-class {
    margin-left: 5.9rem;
    margin-top: 1.2rem;
  }
  .img-res {
    margin-top: unset !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  ::v-deep .theme--light.v-input input,
  .theme--light.v-input textarea {
    max-height: unset !important;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  ::v-deep .theme--light.v-input input,
  .theme--light.v-input textarea {
    max-height: unset !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1920px) {
  ::v-deep .theme--light.v-input input,
  .theme--light.v-input textarea {
    max-height: unset !important;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  ::v-deep .theme--light.v-input input,
  .theme--light.v-input textarea {
    max-height: unset !important;
  }
}
/* responsive New web design */
@media screen and (max-width: 1920px) {
  .textlayout {
    margin-top: 0px !important;
  }
  .img-res img {
    width: 34%;
    height: 34%;
  }
  .LoginText {
    margin-top: 13px !important;
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 1440px) {
  .img-res img {
    width: 25%;
    height: 25%;
  }
  .LoginText {
    font-size: 23px;
  }
  .Loginfield {
    padding-bottom: 30px;
  }
  .Loginfield p {
    font-size: 16px;
  }
  .img-intersection {
    margin-top: 10px;
  }
  .login-phone,
  .reset-btn {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .Loginfield {
    margin-right: 0px !important;
  }
}
@media only screen and (max-width: 959.5px) {
  .Loginfield {
    width: 60%;
    margin-left: 23%;
    padding-bottom: 20px;
  }
  .Logintextfield {
    width: 100% !important;
  }
  .rmtext {
    margin-right: 0% !important;
  }
  .login-phone,
  .reset-btn {
    width: 100% !important;
  }
}
@media only screen and (max-width: 668px) {
  .Loginfield {
    width: 85%;
    margin-left: 8% !important;
  }
}
@media only screen and (max-width: 425.9px) {
  .Loginfield {
    width: 100% !important;
    padding: 20px !important;
    margin-left: 0% !important;
  }
}
</style>
